<template>
  <v-app>
    <v-app-bar app color="{banner.color}" dark>
      <div class="align-center">
        <h1>{{ banner.title }}</h1>
      </div>
      <v-btn text @click.stop="banner.drawer = !banner.drawer">
        <v-app-bar-nav-icon /> 切换
      </v-btn>
    </v-app-bar>
    <v-main>
      <v-container
        class="pa-0"
        style="height: 93vh; padding-top: 50px !important"
        fluid
      >
        <v-row align="start" justify="start" class="fill-height" fluid>
          <v-navigation-drawer
            class="pa-0 pt-2 pb-12 mt-12"
            v-model="banner.drawer"
            fixed
            clipped
            style="min-width: 60px"
          >
            <v-list>
              <v-list-item link>
                <v-list-item-content>
                  <v-list-item-title class="title"> 切换 </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider />

            <v-list nav dense>
              <v-list-item
                link
                v-for="o in origin.all"
                :key="o.origin"
                @click="
                  () => (
                    (origin.stats = 0),
                    (origin.current = o),
                    (banner.drawer = false)
                  )
                "
              >
                <v-list-item-icon> <v-icon>mdi-star</v-icon> </v-list-item-icon>
                <v-list-item-title>{{ o.name }}排行榜</v-list-item-title>
              </v-list-item>

              <v-divider />

              <v-list-item
                link
                v-for="o in origin.all"
                :key="o.origin"
                @click="
                  () => (
                    (origin.stats = 1),
                    (origin.current = o),
                    (banner.drawer = false)
                  )
                "
              >
                <v-list-item-icon>
                  <v-icon>mdi-star</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ o.name }}成绩分布</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-navigation-drawer>
          <v-container
            class="fill-height ma-0 pa-0"
            style="overflow-y: auto; margin-left: 10% !important"
            fluid
            justify="start"
            align="start"
            no-gutter
          >
            <Ranklist
              v-if="!origin.stats"
              :origin="origin.current"
              :mode="mode"
            />
            <Statistics
              v-if="origin.stats"
              :origin="origin.current"
              :mode="mode"
            />
          </v-container>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Ranklist from "./components/Ranklist";
import Statistics from "./components/Statistics";
import { Watermark } from "@pansy/watermark";
const watermark = new Watermark({
  text: "1970-01-01 00:00:00",
  blindFontSize: 40,
});
watermark.show();
export default {
  name: "App",

  components: { Ranklist, Statistics },

  data: () => {
    const mode = "CSP"; // "GESP"
    const title = {
      CSP: "第36次 CCF CSP 认证",
      // CSP: "第7次 CCF PTA 认证",
      GESP: "CCF GESP 2024年12月认证",
    }[mode];
    // const title = "第35次 CCF CSP 认证";
    // const title = "第7次 CCF PTA 认证";
    // const title = "第7次 CCF PTA 认证";
    // <!--h1>第27次CCF CSP编程培训师资认证</h1-->
    //     <!-- <h1>苏州市青少年C++编程挑战赛 2023年6月</h1> -->
    //     <!-- <h1>CCF GESP 2024年9月认证</h1> -->
    //     <!-- <h1></h1>  -->
    const origins = {
      CSP: [{ id: 0, name: "" }],
      GESP: [
        { id: 0, name: "" },
        { id: 1, name: "C++ 一级" },
        { id: 2, name: "C++ 二级" },
        { id: 3, name: "C++ 三级" },
        { id: 4, name: "C++ 四级" },
        { id: 5, name: "C++ 五级" },
        { id: 6, name: "C++ 六级" },
        { id: 7, name: "C++ 七级" },
        { id: 8, name: "C++ 八级" },
        { id: 9, name: "Python 一级" },
        { id: 10, name: "Python 二级" },
        { id: 11, name: "Python 三级" },
        { id: 12, name: "Python 四级" },
        { id: 13, name: "Python 五级" },
        { id: 14, name: "Python 六级" },
        { id: 15, name: "Python 七级" },
        // { id: 16, name: "Python 八级" },
      ],
    };
    const data = {
      mode,
      banner: { title, color: "#000000", drawer: true },
      origin: { current: { id: 0, name: "" }, stats: 0, all: origins[mode] },
      watermark: {
        title: title,
        color: {
          now: [0x20, 0x20, 0x20],
          min: [0x20, 0x20, 0x20],
          max: [0xe0, 0xe0, 0xe0],
          dlt: [1, 1, 1],
        },
      },
    };
    return data;
  },
  methods: {
    tickWatermark() {
      const timeStr = new Date().toLocaleString();
      watermark.update({
        text: [this.watermark.title, timeStr],
        fontSize: 24,
        gapX: 600,
        gapY: 300,
      });
      let app = document.querySelector("header");
      const idx = Math.floor(Math.random() * 3);
      this.watermark.color.now[idx] += this.watermark.color.dlt[idx];
      if (
        this.watermark.color.now[idx] > this.watermark.color.max[idx] ||
        this.watermark.color.now[idx] < this.watermark.color.min[idx]
      )
        this.watermark.color.dlt[idx] = -this.watermark.color.dlt[idx];
      app.style.backgroundColor = `rgb(${this.watermark.color.now[0]},${this.watermark.color.now[1]},${this.watermark.color.now[2]})`;
    },
  },
  mounted: async function () {
    setInterval(this.tickWatermark, 50);
  },
};
</script>
