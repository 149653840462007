<template>
  <v-container>
    <v-row class="text-center aligh-center">
      <v-card class="mx-auto" width="90%">
        <v-row class="align-center" style="height: 100px">
          <v-spacer />
          <!--v-img width="10%"  src="CCSP_Y.jpeg"/-->
          <v-spacer />
          <h1>{{ origin.name }}分数分布</h1>
          <v-spacer />
          <v-spacer />
        </v-row>
        <div
          class="echart"
          id="scoreStatistics"
          :style="scoreStatisticsStyle"
        ></div>
        <!-- <v-card-actions>
          v-btn color="primary"  @click="search()">刷新</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import * as echarts from "echarts";
export default {
  name: "Statistics",
  props: ["origin", "mode"],
  data: () => ({
    staticData: {},
    scoreStatisticsStyle: { float: "left", width: "100%", height: "700px" },
    loading: true,
    options: {},
    desserts: [],
  }),
  mounted: async function () {
    await this.getDataFromApi();
  },
  watch: {
    options: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    origin: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    async getDataFromApi() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      const password =
        decodeURIComponent(
          (new RegExp("[?|&]" + "password" + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || ["", ""])[1].replace(/\+/g, "%20")
        ) || null;

      const problemsSet = {
        CSP: ["第一题", "第二题", "第三题", "第四题", "第五题"],
        GESP: ["选择题", "判断题", "编程题1", "编程题2"],
      };
      const problems = problemsSet[this.mode];
      const items = [...problems, "总分"];
      const series = items.map((name) => ({
        type: "line",
        lineStyle: { width: 2.5 },
        smooth: true,
        symbolSize: 0,
        data: [],
        name,
        label: { show: false, position: "top" },
      }));

      let query = { size: -1, page: -1, origin: this.origin.id };
      if (password) query.password = password;
      const queryString = Object.entries(query)
        .map(([key, value]) => `${key}=${value}`)
        .join("&");
      const that = this;
      this.staticData = {
        xAxis: { name: "人数", data: [] },
        legend: { data: items, top: "bottom" },
        yAxis: { name: "分数" },
        series,
      };
      this.$http.get("/api/ranklist?" + queryString).then(function (res) {
        const N = problems.length;
        that.desserts = res.data.data;
        that.totalDesserts = res.data.total;
        that.staticData.xAxis.data = [];
        for (let k = 0; k < N; k += 1) that.staticData.series[k].data = [];
        that.staticData.series[N].data = [];
        for (let i = 0; i < that.desserts.length; i++) {
          that.staticData.xAxis.data.push(i + 1);
          that.desserts[i].rank = i + 1 + (page - 1) * itemsPerPage;
          for (let j = 0; j < N; j += 1) {
            let x = that.desserts[i].details[j];
            if (x) x = x.score;
            else x = 0;
            that.staticData.series[j].data.push(x);
          }
          const xx = that.desserts[i].total_score;
          that.staticData.series[N].data.push(xx);
        }
        for (let ii = 0; ii < N + 1; ++ii)
          that.staticData.series[ii].data.sort((a, b) => b - a);
        const myChart = echarts.init(
          document.getElementById("scoreStatistics")
        );
        myChart.setOption(that.staticData);
        window.addEventListener("resize", () => {
          myChart.resize();
        });
        that.loading = false;
      });
      setTimeout(this.getDataFromApi, 60 * 1000);
    },
    updateData() {
      const myChart = echarts.init(document.getElementById("scoreStatistics"));
      myChart.setOption(this.staticData);
    },
  },
};
</script>
